import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import EditInfluencerProfile from "../components/edit_influencer_profile/edit_influencer_profile";
import Header from "../components/header/header";

function EditInfluencerProfilePage() {
  return (
    <ChakraProvider>
      <Header />
      <EditInfluencerProfile />
    </ChakraProvider>
  );
}

export default EditInfluencerProfilePage;
