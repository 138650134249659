import { Box, Center, Image, Textarea, Input } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { COLORS_PRIMARY } from "../shared/opalite_colors";
import TempProfile from "../../images/temp_profile_picture.svg";
import {
  OpaliteTextHeader,
  OpaliteTextStandard,
  OpaliteTextSubTitle,
  OpaliteTextTitle,
} from "../shared/opalite_text";
import ProfilePlusIcon from "../../images/profile_plus_icon.svg";
import InstagramIcon from "../../images/verify_socials_icons/verify_socials_icon_instagram_verified.svg";
import TikTokIcon from "../../images/verify_socials_icons/verify_socials_icon_tik_tok_verified.svg";
import AddMoreIcon from "../../images/pngs/add_more_icon.png";
import { OpaliteButtonStandard } from "../shared/opalite_buttons";
import { ApiContext, UserContext } from "../Root";

interface EditInfluencerProfileProps {}

const EditInfluencerProfile: React.FC<EditInfluencerProfileProps> = (props) => {
  const user = useContext(UserContext);
  const api = useContext(ApiContext);
  const [socials, setSocials] = useState([
    { socialType: "instagram", socialHandle: "@influencer" },
    { socialType: "tik_tok", socialHandle: "@influencer" },
  ]);
  const [userBio, setUserBio] = useState("");
  const [website, setWebsite] = useState("");
  const [influencerHandle, setInfluencerHandle] = useState("influencer");

  const handleButtonSubmit = async () => {
    const influencerRequest = {
      influencer_description: userBio,
      website_url: website,
    };
    const res = await api.client.updateInfluencer(influencerRequest);
    console.log("res", res);
  };

  useEffect(() => {}, []);

  const renderSocials = (socials) => {
    console.log(socials);
    const determineIcon = (iconType) => {
      switch (iconType) {
        case "instagram":
          return InstagramIcon;
        case "tik_tok":
          return TikTokIcon;
      }
    };
    return socials.map((social) => {
      return (
        <Box marginBottom="1rem" display="flex" flexDirection="row">
          <Image
            marginRight="2rem"
            width="2rem"
            src={determineIcon(social.socialType)}
          />
          <OpaliteTextStandard>{social.socialHandle}</OpaliteTextStandard>
        </Box>
      );
    });
  };
  return (
    <Box height="100vh" backgroundColor={COLORS_PRIMARY.BACKGROUND}>
      <Image
        position="fixed"
        top="6rem"
        left="2rem"
        width="6rem"
        src={TempProfile}
      />
      <Image position="fixed" top="10rem" left="6.5rem" src={ProfilePlusIcon} />
      <OpaliteTextSubTitle position="fixed" left="9rem" top="8rem">
        @{influencerHandle}
      </OpaliteTextSubTitle>
      <Box
        height="53vh"
        overflow="scroll"
        position="fixed"
        top="15rem"
        left="2rem"
      >
        <OpaliteTextTitle fontSize="2rem">Edit Profile</OpaliteTextTitle>
        <Box marginTop="2rem" display="flex" flexDirection="row">
          <OpaliteTextHeader>Socials</OpaliteTextHeader>
          <Box marginLeft="2rem">
            {renderSocials(socials)}
            <Image width="2rem" src={AddMoreIcon} />
          </Box>
        </Box>
        <Box marginTop="2rem" display="flex" flexDirection="row">
          <OpaliteTextHeader>User bio</OpaliteTextHeader>
          <Textarea
            color={COLORS_PRIMARY.FONT}
            onChange={(e) => setUserBio(e.target.value)}
            marginLeft="2rem"
            width="70%"
          ></Textarea>
          <Box marginTop="2rem" display="flex" flexDirection="row"></Box>
        </Box>
        <Box marginTop="2rem" display="flex" flexDirection="row">
          <OpaliteTextHeader>Website</OpaliteTextHeader>
          <Input
            color={COLORS_PRIMARY.FONT}
            onChange={(e) => setWebsite(e.target.value)}
            marginLeft="2rem"
            width="70%"
          ></Input>
          <Box marginTop="2rem" display="flex" flexDirection="row"></Box>
        </Box>
      </Box>
      <Center position="fixed" bottom="0" width="100%" height="5rem">
        <OpaliteButtonStandard
          onClick={handleButtonSubmit}
          marginBottom=".5rem"
          width="90%"
        >
          I'm done!
        </OpaliteButtonStandard>
      </Center>
    </Box>
  );
};

export default EditInfluencerProfile;
